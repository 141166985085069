import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FaRegCalendarAlt } from "react-icons/fa";
import { VscSymbolEnumMember } from "react-icons/vsc";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EmailIcon from '@mui/icons-material/Email';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ScaleIcon from '@mui/icons-material/Scale';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ReorderIcon from '@mui/icons-material/Reorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LabelIcon from '@mui/icons-material/Label';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StyleIcon from '@mui/icons-material/Style';
import SellIcon from '@mui/icons-material/Sell';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SchoolIcon from '@mui/icons-material/School';
import { logout } from '../utils/functions';
import { saveAs } from 'file-saver';


const MysideBar = () => {
  const location = useLocation();
 
  // Assuming you have the user's role stored in localStorage
  const userRole = localStorage.getItem('token1');
  const fullname = localStorage.getItem('token3');

  const handleDownloadTemplate = () => {
    // Download the template file
    saveAs(process.env.PUBLIC_URL + '/data_template.xlsx', 'data_template.xlsx');
  };


  return (
    <div style={{ display: "flex", height: "100vh"}}
    >

      <Sidebar className="MysideBar" >
        
        <Menu >

          <MenuItem className="menu1" icon={<MenuRoundedIcon />} >
          <h3><b>{fullname}</b></h3>
          </MenuItem>
          <hr  /> 

          {userRole === "Dealer" ? (
            <>
              {/* Dealer can only access Create Order and Review Orders in Sales */}
              <SubMenu label="Buy" icon={<StorefrontIcon style={{ color: "#A9A9A9" }} />}>
                <MenuItem className={location.pathname === "/sales" ? "activeMenuItem" : ""} icon={<StyleIcon style={{ color: location.pathname ==="/sales" ? "grey" : "#A9A9A9" }} />} component={<Link to="/sales" />}>Create Order</MenuItem>
                <MenuItem className={location.pathname === "/salesreview" ? "activeMenuItem" : ""} icon={<SellIcon style={{ color: location.pathname ==="/salesreview" ? "grey" : "#A9A9A9" }} />} component={<Link to="/salesreview" />}>My Orders</MenuItem>
              </SubMenu>
              {/* Logout option for Dealer */}
            </>
          ) : null}
          



{userRole === "Admin" || userRole === "FranchiseeAdmin" ? (
  <>
            <MenuItem className={location.pathname === "/" ? "activeMenuItem" : ""} icon={<DashboardIcon style={{ color: location.pathname === "/" ? "grey" : "#A9A9A9" }} />} component={<Link to="/" />}> Dashboard</MenuItem>

 </>
) : null}

{userRole !== "Dealer" && (
  <MenuItem 
    className={location.pathname === "/appoinmentscalendar" ? "activeMenuItem" : ""} 
    icon={<FaRegCalendarAlt style={{ color: location.pathname ==="/appoinmentscalendar" ? "grey" : "#A9A9A9" }} />} 
    component={<Link to="/appoinmentscalendar" />}
  >
    Appointments
  </MenuItem>
)}


{userRole === "Admin" ? (
  <>
      <SubMenu label="Master Data" icon={<AllInclusiveIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/category" ? "activeMenuItem" : ""} icon={<LabelIcon style={{ color: location.pathname ==="/category" ? "grey" : "#A9A9A9" }} />} component={<Link to="/category" />}>Category</MenuItem>
        <MenuItem className={location.pathname === "/uom" ? "activeMenuItem" : ""} icon={<ScaleIcon style={{ color: location.pathname ==="/uom" ? "grey" : "#A9A9A9" }} />} component={<Link to="/uom" />}>Unit of Measure</MenuItem>
        <MenuItem className={location.pathname === "/currency" ? "activeMenuItem" : ""} icon={<MonetizationOnIcon style={{ color: location.pathname ==="/currency" ? "grey" : "#A9A9A9" }} />} component={<Link to="/currency" />}>Currencies</MenuItem>
              <MenuItem className={location.pathname === "/location" ? "activeMenuItem" : ""} icon={<MapIcon style={{ color: location.pathname ==="/location" ? "grey" : "#A9A9A9" }} />} component={<Link to="/location" />}>Locations</MenuItem>
              <MenuItem className={location.pathname === "/customer" ? "activeMenuItem" : ""} icon={<SensorOccupiedIcon style={{ color: location.pathname ==="/customer" ? "grey" : "#A9A9A9" }} />} component={<Link to="/customer" />}>Customers</MenuItem>
              <MenuItem className={location.pathname === "/supplier" ? "activeMenuItem" : ""} icon={<LocalShippingIcon style={{ color: location.pathname ==="/supplier" ? "grey" : "#A9A9A9" }} />} component={<Link to="/supplier" />}>Suppliers</MenuItem>
              <MenuItem className={location.pathname === "/product" ? "activeMenuItem" : ""} icon={<CategoryIcon style={{ color: location.pathname ==="/product" ? "grey" : "#A9A9A9" }} />} component={<Link to="/product" />}>Products</MenuItem>
              <MenuItem className={location.pathname === "/pricebylocation" ? "activeMenuItem" : ""} icon={<PriceCheckIcon style={{ color: location.pathname ==="/pricebylocation" ? "grey" : "#A9A9A9" }} />} component={<Link to="/pricebylocation" />}>Location Pricing</MenuItem>
      </SubMenu>
  </>
) : userRole === "FranchiseeAdmin" ? (
  <>
      <SubMenu label="Master Data" icon={<AllInclusiveIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/customer" ? "activeMenuItem" : ""} icon={<SensorOccupiedIcon style={{ color: location.pathname ==="/customer" ? "grey" : "#A9A9A9" }} />} component={<Link to="/customer" />}>Customers</MenuItem>
      </SubMenu>
  </>
) : null}




{userRole === "Admin" || userRole === "FranchiseeAdmin" ? (
  <>
      <SubMenu label="Accounts" icon={<AttachMoneyIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/transactions" ? "activeMenuItem" : ""} icon={<ReceiptIcon style={{ color: location.pathname ==="/transactions" ? "grey" : "#A9A9A9" }} />} component={<Link to="/transactions" />}>Transactions</MenuItem>
        <MenuItem className={location.pathname === "/profitloss" ? "activeMenuItem" : ""} icon={<AccountBalanceIcon style={{ color: location.pathname ==="/profitloss" ? "grey" : "#A9A9A9" }} />} component={<Link to="/profitloss" />}>P&L</MenuItem>
      </SubMenu>
  </>
) : null}





{userRole === "Admin" || userRole === "FranchiseeAdmin" ? (
  <>
      <SubMenu label="Leads & Estimates" icon={<RecordVoiceOverIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/leads" ? "activeMenuItem" : ""} icon={<ThreePIcon style={{ color: location.pathname ==="/leads" ? "grey" : "#A9A9A9" }} />} component={<Link to="/leads" />}>Add Leads</MenuItem>
        <MenuItem className={location.pathname === "/appointments" ? "activeMenuItem" : ""} icon={<FaRegCalendarAlt  style={{ color: location.pathname ==="/appointments" ? "grey" : "#A9A9A9" }} />} component={<Link to="/appointments" />}>Set Appointments</MenuItem>
        <MenuItem className={location.pathname === "/estimates" ? "activeMenuItem" : ""} icon={<BatchPredictionIcon style={{ color: location.pathname ==="/estimates" ? "grey" : "#A9A9A9" }} />} component={<Link to="/estimates" />}>Create Estimate</MenuItem>
        <MenuItem className={location.pathname === "/reviewestimates" ? "activeMenuItem" : ""} icon={<RemoveRedEyeIcon style={{ color: location.pathname ==="/reviewestimates" ? "grey" : "#A9A9A9" }} />} component={<Link to="/reviewestimates" />}>Review Estimates</MenuItem>
        <MenuItem className={location.pathname === "/estimatephotos" ? "activeMenuItem" : ""} icon={<CenterFocusWeakIcon style={{ color: location.pathname ==="/estimatephotos" ? "grey" : "#A9A9A9" }} />} component={<Link to="/estimatephotos" />}>Estimate Photos</MenuItem>
        
        <MenuItem className={location.pathname === "/emailtrack" ? "activeMenuItem" : ""} icon={<SendIcon  style={{ color: location.pathname ==="/emailtrack" ? "grey" : "#A9A9A9" }} />} component={<Link to="/emailtrack" />}>Tracking</MenuItem>

      </SubMenu>
  </>
) : userRole === "Estimator" ? (
  <>
      <SubMenu label="Leads Management" icon={<RecordVoiceOverIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/estimates" ? "activeMenuItem" : ""} icon={<BatchPredictionIcon style={{ color: location.pathname ==="/estimates" ? "grey" : "#A9A9A9" }} />} component={<Link to="/estimates" />}>Create Estimate</MenuItem>
        <MenuItem className={location.pathname === "/reviewestimates" ? "activeMenuItem" : ""} icon={<RemoveRedEyeIcon style={{ color: location.pathname ==="/reviewestimates" ? "grey" : "#A9A9A9" }} />} component={<Link to="/reviewestimates" />}>Review Estimates</MenuItem>
        <MenuItem className={location.pathname === "/estimatephotos" ? "activeMenuItem" : ""} icon={<CenterFocusWeakIcon style={{ color: location.pathname ==="/estimatephotos" ? "grey" : "#A9A9A9" }} />} component={<Link to="/estimatephotos" />}>Estimate Photos</MenuItem>

      </SubMenu>
  </>
) : null}




{userRole === "Admin" || userRole === "FranchiseeAdmin" ? (
  <>
      <SubMenu label="Buy" icon={<ShoppingCartIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/purchase" ? "activeMenuItem" : ""} icon={<ReorderIcon style={{ color: location.pathname ==="/purchase" ? "grey" : "#A9A9A9" }} />} component={<Link to="/purchase" />}>Create Order</MenuItem>
        <MenuItem className={location.pathname === "/purchasereview" ? "activeMenuItem" : ""} icon={<BackupTableIcon style={{ color: location.pathname ==="/purchasereview" ? "grey" : "#A9A9A9" }} />} component={<Link to="/purchasereview" />}>Purchase Orders</MenuItem>
        <MenuItem className={location.pathname === "/purchasefiles" ? "activeMenuItem" : ""} icon={<FolderOpenIcon style={{ color: location.pathname ==="/purchasefiles" ? "grey" : "#A9A9A9" }} />} component={<Link to="/purchasefiles" />}>Upload Files</MenuItem>
      </SubMenu>
  </>
) : null}


 {userRole === "Admin" || userRole === "FranchiseeAdmin" || userRole === "Installer"|| userRole === "Supervisor" ? (
  <>
      <SubMenu label="Sell" icon={<StorefrontIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/sales" ? "activeMenuItem" : ""} icon={<StyleIcon style={{ color: location.pathname ==="/sales" ? "grey" : "#A9A9A9" }} />} component={<Link to="/sales" />}>Create Order</MenuItem>
        <MenuItem className={location.pathname === "/salesreview" ? "activeMenuItem" : ""} icon={<SellIcon style={{ color: location.pathname ==="/salesreview" ? "grey" : "#A9A9A9" }} />} component={<Link to="/salesreview" />}>Sales Orders</MenuItem>
        <MenuItem className={location.pathname === "/salesorderphotos" ? "activeMenuItem" : ""} icon={<CenterFocusWeakIcon style={{ color: location.pathname ==="/salesorderphotos" ? "grey" : "#A9A9A9" }} />} component={<Link to="/salesorderphotos" />}>Orders Photos</MenuItem>
      </SubMenu>
  </>
) : null}


{userRole === "Admin" ? (
  <>
      <SubMenu label="Transfer" icon={<MoveUpIcon style={{ color: "#A9A9A9" }} />}>
        <MenuItem className={location.pathname === "/transfers" ? "activeMenuItem" : ""} icon={<ViewCarouselIcon style={{ color: location.pathname ==="/transfers" ? "grey" : "#A9A9A9" }} />} component={<Link to="/transfers" />}>Create Order</MenuItem>
        <MenuItem className={location.pathname === "/transfersreview" ? "activeMenuItem" : ""} icon={<FormatListNumberedIcon style={{ color: location.pathname ==="/transfersreview" ? "grey" : "#A9A9A9" }} />} component={<Link to="/transfersreview" />}>Transfer Orders</MenuItem>
        <MenuItem className={location.pathname === "/transferfiles" ? "activeMenuItem" : ""} icon={<FolderOpenIcon style={{ color: location.pathname ==="/transferfiles" ? "grey" : "#A9A9A9" }} />} component={<Link to="/transferfiles" />}>Upload Files</MenuItem>
      </SubMenu>
  </>
) : null}


{userRole === "Admin" || userRole === "FranchiseeAdmin" || userRole === "Supervisor" ? (
  <>
      <MenuItem className={location.pathname === "/onhand" ? "activeMenuItem" : ""} icon={<InventoryIcon style={{ color: location.pathname === "/onhand" ? "grey" : "#A9A9A9" }} />} component={<Link to="/onhand" />}> On Hand Inventory</MenuItem>
  </>
) : null}

{userRole === "Admin" ? (
  <>
      <MenuItem className={location.pathname === "/franchisee" ? "activeMenuItem" : ""} icon={<VscSymbolEnumMember  style={{ color: location.pathname ==="/franchisee" ? "grey" : "#A9A9A9" }} />} component={<Link to="/franchisee" />}>Franchisees</MenuItem>
  </>
) : null}

{userRole !== "Dealer" && (
  <MenuItem 
    className={location.pathname === "/university" ? "activeMenuItem" : ""} 
    icon={<SchoolIcon style={{ color: location.pathname === "/university" ? "grey" : "#A9A9A9" }} />} 
    component={<Link to="/university" />}
  >
    University
  </MenuItem>
)}

{userRole === "Admin" ? (
  <>
<MenuItem className={location.pathname === "/emails" ? "activeMenuItem" : ""} icon={<EmailIcon  style={{ color: location.pathname ==="/emails" ? "grey" : "#A9A9A9" }} />} component={<Link to="/emails" />}>Emails</MenuItem>
  </>
) : null}


{userRole === "Admin" || userRole === "FranchiseeAdmin" ? (
  <>
      <MenuItem className={location.pathname === "/users" ? "activeMenuItem" : ""} icon={<PersonAddIcon  style={{ color: location.pathname ==="/users" ? "grey" : "#A9A9A9" }} />} component={<Link to="/users" />}>Add Users</MenuItem>
  </>
) : null}


  <MenuItem onClick={logout} icon={<LogoutOutlinedIcon style={{ color: location.pathname === "/logout" ? "grey" : "#757575" }} />}> Logout</MenuItem>




        </Menu>
      </Sidebar>
    </div>
  );
};

export default MysideBar;