import React, { useState, useEffect } from 'react';
import { Drawer, Table, Select, Button, notification } from 'antd';
import axios, { AxiosResponse } from 'axios'; // Import axios for HTTP requests
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getAuthToken } from '../utils/functions';
import { AuthTokenType } from '../utils/types';
import { salesordereditdateViewUrl, salesorderupdatesUrl } from '../utils/network';

const { Option } = Select;

interface DetailDrawerProps {
  currentItem: any;
  isModalVisible: boolean;
  handleOk: () => void;
  sendemail: (item: any) => void;
  downloadso: (item: any) => void;
  handleMailTo: () => void;
  onUpdateSuccess: () => void; // Add this prop
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({ currentItem, isModalVisible, handleOk, sendemail, downloadso, handleMailTo, onUpdateSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [paymentMode, setPaymentMode] = useState<string>('');
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userRole = localStorage.getItem('token1'); 
console.log(userRole)
  useEffect(() => {
    if (currentItem) {
      setPaymentMode(currentItem.mode);
    }
  }, [currentItem]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (value: string) => {
    setPaymentMode(value);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const datatosend = {
        id: currentItem.id,
        mode: paymentMode,
      };

      const headers = getAuthToken() as AuthTokenType;
      const response: AxiosResponse = await axios.patch(`${salesordereditdateViewUrl}/${datatosend.id}`, datatosend, headers);

      if (response.status === 200) {
        notification.success({
          message: "Operation Success",
          description: "Payment Method Changed"
        });
        onUpdateSuccess(); // Notify parent of successful update
        setIsEditing(false); // Switch back to view mode after save
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      title="Details"
      visible={isModalVisible}
      placement="right"
      onClose={handleOk}
      style={{ borderRadius: '10px' }}
    >
      {currentItem && (
        <>
          <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
            <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
            <p style={{ fontSize: '16px', marginBottom: '8px' }}>Serving Location: {currentItem.from_location?.name}</p>
            <p style={{ fontSize: '16px', marginBottom: '8px' }}>Customer: {currentItem.customer?.name}</p>
            <p style={{ fontSize: '16px', marginBottom: '8px' }}>Type: {currentItem.customer?.type}</p>
            <p style={{ fontSize: '16px', marginBottom: '8px' }}>
              Payment Mode: {isEditing ? (
                <Select
                  value={paymentMode}
                  style={{ width: 200 }}
                  onChange={handleChange}
                >
                  <Option value="cash">Cash</Option>
                  <Option value="check">Check</Option>
                  <Option value="credit_card">Credit Card</Option>
                  <Option value="bank_transfer">Bank Transfer</Option>
                  <Option value="paypal">PayPal</Option>
                  <Option value="zelle">Zelle</Option>
                  <Option value="venmo">Venmo</Option>
                </Select>
              ) : (
                paymentMode
              )}
 {userRole !== "Dealer" && isEditing ? (
                <Button type="primary" onClick={handleSave} style={{ marginLeft: '10px' }} loading={loading}>Save</Button>
              ) : (
                userRole !== "Dealer" && <Button onClick={toggleEdit} style={{ marginLeft: '10px' }}>Edit</Button>
              )}
            </p>
          </div>

          <div>
            <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
            <Table
              className="custom-table"
              dataSource={currentItem.salesorder_items}
              columns={[
                {
                  title: 'Item Name',
                  dataIndex: ['item', 'name'],
                  key: 'name',
                  render: (text: string) => <span>{JSON.stringify(text)}</span>,
                },
                {
                  title: 'Quantity',
                  dataIndex: 'quantity',
                  key: 'quantity',
                  render: (text: number) => <span>{JSON.stringify(text)}</span>,
                },
              ]}
              pagination={false}
              bordered
              size="small"
              style={{ marginTop: '20px' }}
            />
          </div>
        </>
      )}

{userRole !== "Dealer" ? (
  <SpeedDial
    ariaLabel="SpeedDial"
    icon={<SpeedDialIcon />}
    onClose={() => setSpeedDialOpen(false)}
    onOpen={() => setSpeedDialOpen(true)}
    open={speedDialOpen}
    direction="up"
    FabProps={{ size: 'small' }}
  >
    <SpeedDialAction
      key="Send Email"
      icon={<EmailIcon />}
      tooltipTitle="Send Email"
      onClick={() => sendemail(currentItem)}
    />
    <SpeedDialAction
      key="Download"
      icon={<PictureAsPdfIcon />}
      tooltipTitle="Download"
      onClick={() => downloadso(currentItem)}
    />
    <SpeedDialAction
      key="mailTo"
      icon={<AlternateEmailIcon />}
      tooltipTitle="Mail To"
      onClick={handleMailTo}
    />
  </SpeedDial>
) : (
  <SpeedDial
    ariaLabel="SpeedDial"
    icon={<SpeedDialIcon />}
    onClose={() => setSpeedDialOpen(false)}
    onOpen={() => setSpeedDialOpen(true)}
    open={speedDialOpen}
    direction="up"
    FabProps={{ size: 'small' }}
  >
    <SpeedDialAction
      key="Download"
      icon={<PictureAsPdfIcon />}
      tooltipTitle="Download"
      onClick={() => downloadso(currentItem)}
    />
  </SpeedDial>
)}
    </Drawer>
  );
};

export default DetailDrawer;