import React, { FC, useEffect, useState } from "react";
import { Drawer, notification, Form, Input, Select, Button } from "antd";
import { AuthTokenType, DataProps, AddUserFormModalProps, UserProps, FranchiseeProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { CreateUserUrl, UsersUrl } from "../utils/network";
import { useGetFranchisee } from "../utils/hooks";

const { Option } = Select;

interface AddUserFormProps extends AddUserFormModalProps {
  editingUser?: UserProps | null;
}

const AddUserForm: FC<AddUserFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingUser,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetFranchisee(setfranchisee, setFetching);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  useEffect(() => {
    if (editingUser) {
      form.setFieldsValue({...editingUser,
        franchisee_id: editingUser.franchisee.id,
    });
    } else {
      form.resetFields();
    }
  }, [editingUser, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingUser) {
        // Editing user
        response = await axios.put(`${UsersUrl}/${editingUser.id}`, values, headers);
      } else {
        // Adding new user
        response = await axios.post(CreateUserUrl, values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingUser ? "Edit User" : "Add User"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item
          label="Name"
          name="fullname"
          rules={[{ required: true, message: 'Please input the full name!' }]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input the email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: 'Please select the role!' }]}
        >
          <Select placeholder="Role">
            <Option value="FranchiseeAdmin">FranchiseeAdmin</Option>
            <Option value="Installer">Installer</Option>
            <Option value="Estimator">Estimator</Option>
            <Option value="Supervisor">Supervisor</Option>
            <Option value="Dealer">Dealer</Option>

          </Select>
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: false, message: 'Please input the color for calendar!' }]}
        >
          <Input placeholder="Color" />
        </Form.Item>
        <Form.Item
          label="Franchisee"
          name="franchisee_id"
          rules={[{ required: true, message: 'Please select Franchisee!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Franchisee</Select.Option>
            {franchisee.map(franchisee => (
              <Select.Option key={franchisee.id} value={franchisee.id}>
                {franchisee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingUser ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddUserForm;