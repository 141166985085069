import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios, { AxiosResponse } from "axios";
import { AuthTokenType, salesProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import { redirecturl, salesordereditdateViewUrl, salesorderupdatesUrl } from "../utils/network";
import { notification } from "antd";

const CheckoutForm: React.FC<{ clientSecret: string; salesId: number; dpmCheckerLink: string }> = ({ clientSecret, salesId, dpmCheckerLink }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const notify = async (amount: number) => {
    const datatosend = {
      id: salesId,
      paid: amount,
    };

    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    const response: AxiosResponse = await axios.patch(salesordereditdateViewUrl + '/' + datatosend.id, datatosend, headers).catch(
      (e) => {
        notification.error({
          message: "Operation Error",
          description: e.response?.data.error,
        });
      }
    ) as AxiosResponse;

    setLoading(false);

    if (response) {
      notification.success({
        message: "Operation Success",
        description: "Payment Success",
      });
      setTimeout(() => {
        window.location.href = redirecturl;
      }, 2000);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirecturl, // Replace with your actual return URL
      },
      redirect: "if_required", // Prevent redirection after confirming payment
    });

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
      setIsLoading(false);
      return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        setMessage("Payment successful!");
        notify(paymentIntent.amount/100); // Notify backend of payment success
      } else {
        setMessage(`Payment status: ${paymentIntent.status}`);
      }
    } else {
      setMessage("Unable to retrieve payment status.");
    }

    setIsLoading(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
 <br/>
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          style={{
            backgroundColor: '#4CAF50', // Green background
            color: 'white', // White text
            padding: '10px 20px', // Padding
            textAlign: 'center', // Centered text
            textDecoration: 'none', // No underline
            display: 'inline-block', // Inline-block display
            fontSize: '16px', // Font size
            border: 'none', // No border
            borderRadius: '4px', // Rounded corners
            cursor: 'pointer', // Pointer cursor on hover
          }}
        >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
      <div id="dpm-annotation">
        <p>
          Payment methods are dynamically displayed based on customer location, order amount, and currency.&nbsp;
          <a href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">Preview payment methods by transaction</a>
        </p>
      </div>
    </>
  );
};

export default CheckoutForm;