import React, { useState } from "react";
import { notification, Input, Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import { getAuthToken } from "../utils/functions";
import { redirecturl, salesordereditdateViewUrl, salesorderupdatesUrl } from "../utils/network";
import { AuthTokenType } from "../utils/types";

const OtherPayment: React.FC<{ salesId: number }> = ({ salesId }) => {
  const [amount, setAmount] = useState<number | "">("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  const notifyBackend = async (amount: number) => {
    const datatosend = {
      id: salesId,
      paid: amount,
    };

    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      const response: AxiosResponse = await axios.patch(`${salesordereditdateViewUrl}/${datatosend.id}`, datatosend, headers);

      notification.success({
        message: "Payment Success",
        description: "Payment was successfully processed.",
      });

      setTimeout(() => {
        window.location.href = redirecturl // Redirect to the appropriate page
      }, 2000);
    } catch (error) {
      notification.error({
        message: "Operation Error",
        description: "Failed to update payment status.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePayClick = async () => {
    if (!amount || amount <= 0) {
      notification.error({
        message: "Invalid Amount",
        description: "Please enter a valid amount.",
      });
      return;
    }

    // Trigger the notifyBackend function directly
    await notifyBackend(amount);
    setModalVisible(false); // Close the modal after the operation
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <span
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setModalVisible(true)}
      >
        Other Payment
      </span>

      <Modal
        title="Enter Amount"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handlePayClick}
        okText="Proceed"
        cancelText="Cancel"
        confirmLoading={loading}
      >
        <Input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
        />
      </Modal>
    </div>
  );
};

export default OtherPayment;