//const BaseUrl="http://127.0.0.1:8000/api/"
//export const redirecturl= "http://localhost:3000/salesreview";

//const BaseUrlupload="http://18.219.133.111/api/"

export const BaseUrl="https://asafepoolpro.com/api/"
export const redirecturl= "https://asafepoolpro.com/salesreview";

export const LoginUrl = BaseUrl+ "user/login"
export const MeUrl = BaseUrl + "user/Me"
export const CreateUserUrl = BaseUrl + "user/create-user"
export const UsersUrl = BaseUrl + "user/users"
export const UpdatePasswordUrl = BaseUrl + "user/update-password"
export const ForgotPasswordUrl = BaseUrl + "user/ForgotPasswordView"
export const ResetPasswordUrl = BaseUrl + "user/ResetPasswordView"
export const FranchiseeUrl = BaseUrl + "user/Franchisee"
export const FranchiseekeyUrl = BaseUrl + "user/FranchiseeKeyView"

export const categoriesUrl = BaseUrl + "masterdata/categories"
export const unitofmeasuresUrl = BaseUrl + "masterdata/unitofmeasures"
export const currenciesUrl = BaseUrl + "masterdata/currencies"
export const locationsUrl = BaseUrl + "masterdata/locations"
export const customersUrl = BaseUrl + "masterdata/customers"
export const customersupdatesUrl = BaseUrl + "masterdata/customerupdates"
export const suppliersUrl = BaseUrl + "masterdata/suppliers"
export const supplierupdatesUrl = BaseUrl + "masterdata/supplierupdates"


export const productsUrl = BaseUrl + "masterdata/products"
export const customerssalesUrl = BaseUrl + "masterdata/customerssales"



export const salesorderUrl = BaseUrl + "transactiondata/salesorder"
export const salesorderphotosUrl = BaseUrl + "transactiondata/salesorderphotos"
export const transferorderfilesUrl = BaseUrl + "transactiondata/transferorderfiles"
export const purchaseorderfilesUrl = BaseUrl + "transactiondata/purchaseorderfiles"
export const salesorderwithemployeeUrl = BaseUrl + "transactiondata/salesorderwithemployee"
export const purchaseorderUrl = BaseUrl + "transactiondata/purchaseorder"
export const purchaseorderupdatesUrl = BaseUrl + "transactiondata/purchaseorderupdates"

export const salesorderupdatesUrl = BaseUrl + "transactiondata/salesorderupdateView"
export const salesorderViewwithemployeeupdateUrl = BaseUrl + "transactiondata/salesorderViewwithemployeeupdate"
export const salesordereditdateViewUrl = BaseUrl + "transactiondata/salesordereditdateView"



export const stocktransferupdatesUrl = BaseUrl + "transactiondata/stocktransferorderupdateView"




export const stocktransferorderUrl = BaseUrl + "transactiondata/stocktransferorder"
export const onhandUrl = BaseUrl + "transactiondata/onhandinventory"
export const InventoryUrl = BaseUrl + "transactiondata/InventoryViewSet"
export const PricebyLocationUrl = BaseUrl + "masterdata/pricebylocation"

export const sendsoUrl = BaseUrl + "transactiondata/salesorder/send_email_sales_order"
export const viewsoUrl = BaseUrl + "transactiondata/salesorder/view_sales_order"
export const salesordercloseUrl = BaseUrl + "transactiondata/salesordercloseView"


export const sendstoUrl = BaseUrl + "transactiondata/stocktransferorder/send_email_transfer_order"
export const viewstoUrl = BaseUrl + "transactiondata/stocktransferorder/view_transfer_order"


export const sendpoUrl = BaseUrl + "transactiondata/purchaseorder/send_email_purchase_order"
export const viewpoUrl = BaseUrl + "transactiondata/purchaseorder/view_purchase_order"


export const leadsUrl = BaseUrl + "crm/leads"
export const estimateformUrl = BaseUrl + "crm/EstimatesFormView/get_pdf"
export const estimatesUrl = BaseUrl + "crm/estimates"
export const estimateseditUrl = BaseUrl + "crm/estimateseditView"
export const estimatescalendarUrl = BaseUrl + "crm/estimatesCalendar"
export const estimatephotosUrl = BaseUrl + "crm/estimatephotos"
export const appointmentsUrl = BaseUrl + "crm/appointments"
export const leadsestimatesUrl = BaseUrl + "crm/leadsestimates"



export const SummaryMasterUrl = BaseUrl + "transactiondata/summarymasterview"
export const SummaryTransactionUrl = BaseUrl + "transactiondata/summarytransactionview"



export const InventorySummaryUrl = BaseUrl + "transactiondata/onhandsummaryview"
export const SalesOrderCalendarURL = BaseUrl + "transactiondata/salesorderCalendar"

export const masterdatauploadURL = BaseUrl + "masterdata/UploadCSV/"

export const InventoryMD04Url = BaseUrl + "transactiondata/InventoryViewSetMD04"

export const headersurls = BaseUrl + "finance/headers"
export const subheadersurls = BaseUrl + "finance/subheaders"

export const transactionsurls = BaseUrl + "finance/transactions"
export const profitlossstatementsurls = BaseUrl + "finance/profitlossstatements"
export const headerprofitlossurls = BaseUrl + "finance/headerprofitloss"
export const transactionsupdateurls = BaseUrl + "finance/transactionsupdate"


export const appointmentsupdateurls = BaseUrl + "crm/appointmentsupdateView"
export const leadsupdateurls = BaseUrl + "crm/leadsupdateView"

export const estimatesupdateViewurls = BaseUrl + "crm/estimatesupdateView"




export const sankeycharturls = BaseUrl + "finance/sankeychart"

export const dashboardurls = BaseUrl + "finance/DashboardView"

export const emailsurls = BaseUrl + "crm/emailcontentview"

export const emailTrackingUrl = BaseUrl + "crm/emailtrackingview"
export const emailupdateTrackingUrl = BaseUrl + "crm/emailtrackingupdatesview"


export const paymentsUrl = BaseUrl + "finance/payments"

export const conversationsUrl = BaseUrl + "crm/conversationsview"
export const getconversationsUrl = BaseUrl + "crm/conversationsview/get_conversations_by_lead"
export const sendconversationsUrl = BaseUrl + "crm/conversationsview/send_sms"


export const sectionsUrl = BaseUrl + "university/sections/"
export const lessonsUrl = BaseUrl + "university/lessons/"
export const contentsUrl = BaseUrl + "university/contents/"
