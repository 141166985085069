import {useState,FC} from "react"
import { useAuth } from "../utils/hooks"
import { logout } from "../utils/functions"
import Layout from "./Layout";
import { Navigate } from "react-router-dom";

interface Props {
    children: React.ReactNode;
  }

const AuthRoute:React.FC<Props> = ({ children }) =>{
    const [loading,setLoading]= useState(true)
    useAuth({
        errorCallBack:()=>{
            logout()},
        successCallBack:()=>{
            setLoading(false)
        }
    })

    if(loading) {
        return <i>loading...</i>
    }
    const userRole = localStorage.getItem('token1');

    // Redirect "Dealer" users from "/" to "/sales"
    if (userRole === "Dealer" && window.location.pathname === "/") {
        return <Navigate to="/sales" />;
    }
    return <Layout>{children}</Layout>

}
export default AuthRoute